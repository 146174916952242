import { Button, Modal, ModalProps, Typography } from "antd";
import clsx from "clsx";
import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { FaCheckToSlot } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa6";
import { IoMdCloseCircle } from "react-icons/io";
import { ensureExhaustive } from "../utils";

import styles from "./GenericResultModal.module.scss";

export enum ResultType {
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
}

interface GenericResultModalProps extends ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message: string;
  accentColor: string;
  type: ResultType;
}

const GenericResultModal: React.FC<GenericResultModalProps> = ({
  isOpen,
  onClose,
  title,
  message,
  type,
  accentColor,
  ...modalProps
}) => {
  const renderIcon = () => {
    switch (type) {
      case ResultType.SUCCESS:
        return (
          <FaCheckCircle
            className={clsx(styles.SuccessIcon, styles.GenericIcon)}
          />
        );
      case ResultType.ERROR:
        return (
          <IoMdCloseCircle
            className={clsx(styles.ErrorIcon, styles.GenericIcon)}
          />
        );
      case ResultType.INFO:
        return (
          <FaCheckToSlot
            className={clsx(styles.InfoIcon, styles.GenericIcon)}
            style={{ color: accentColor }}
          />
        );
      default:
        ensureExhaustive(type);
    }
  };

  return (
    <Modal
      open={isOpen}
      width="37.5rem"
      className={styles.GenericModal}
      footer={null}
      {...modalProps}
      onCancel={onClose}
    >
      <div className={styles.GenericModalContainer}>
        {renderIcon()}
        <Typography.Title level={4} className={styles.GenericTitle}>
          {title}
        </Typography.Title>
        <Typography.Text className={styles.ModalMessage}>
          {message}
        </Typography.Text>
        <Button className={styles.BackButton} onClick={onClose}>
          <FaArrowLeft /> Return to Assurance Profile
        </Button>
      </div>
    </Modal>
  );
};

export default GenericResultModal;
