import {
  Col,
  ConfigProvider,
  ModalProps,
  PopconfirmProps,
  Row,
  theme,
} from "antd";
import clsx from "clsx";
import saveAs from "file-saver";
import { createContext, useRef } from "react";
import AssuranceProfileSearch from "./AssuranceProfileSearch";
import AssuranceProfileSubscription from "./AssuranceProfileSubscription/AssuranceProfileSubscription";
import AssuranceProfileUpdates from "./AssuranceProfileUpdates";
import styles from "./AssuranceProfileWrapper.module.scss";
import CertificationSelections from "./Certifications";
import DocumentsSection from "./DocumentsSection";
import ProfileFooter from "./Footer";
import "./global.scss";
import AssuranceProfileBanner from "./AssurnaceProfileBanner/AssuranceProfileBanner";
import AssuranceProfileLogo from "./Logo/Logo";
import NewAssuranceProfileLaunchMessage from "./NewAssuranceProfileLaunchMessage";
import Overview from "./Overview";
import RequestInfo from "./RequestInfo";
import { RequestInfoFormParams } from "./RequestInfo/Form";
import Subprocessors from "./Subprocessors";
import Tiles from "./Tiles";
import AssuranceProfileTitle from "./Title";
import {
  AssuranceProfileId,
  Certification,
  CertificationSelectionId,
  CustomerAssuranceDocument,
  CustomerAssuranceDocumentId,
  Logo,
  ProfileVersion,
  ProfileVersionId,
  Subprocessor,
  SubprocessorId,
  TileId,
  TileItem,
  TileItemId,
} from "./types/assuranceProfile";
import { downloadFile } from "./utils/helper";

enum AssuranceProfileComponent {
  Certifications = "Certifications",
  Overview = "Overview",
  RequestInfoSettings = "RequestInfoSettings",
  RequestInfo = "RequestInfo",
  Tiles = "Tiles",
  TileItems = "TileItems",
  Updates = "Updates",
  Subprocessors = "Subprocessors",
  Logo = "Logo",
  Title = "Title",
  RequestInfoSubtitle = "RequestInfoSubtitle",
  RequestInfoTitle = "RequestInfoTitle",
  RequestInformationFormHiddenBanner = "RequestInformationFormHiddenBanner",
}

export interface EditUpdateModalProps extends ModalProps {
  updateId: AssuranceProfileId;
}
export interface EditTileModalProps extends ModalProps {
  tileId: TileId;
}

export interface EditSubprocessorModalProps extends ModalProps {
  subprocessor: Subprocessor;
}

export interface DeleteSubprocessorPopconfirmProps {
  subprocessorId: SubprocessorId;
}

export interface DeleteUpdatePopconfirmProps {
  updateId: AssuranceProfileId;
  onConfirm?: () => void;
}

export interface DeleteLogoPopconfirmProps extends PopconfirmProps {
  logo: Logo;
}

interface AssuranceProfileDeletePopconfirms {
  [AssuranceProfileComponent.Subprocessors]: React.FC<DeleteSubprocessorPopconfirmProps>;
  [AssuranceProfileComponent.TileItems]: React.FC<DeleteTileItemPopconfirmProps>;
  [AssuranceProfileComponent.Tiles]: React.FC<DeleteTilePopconfirmProps>;
  [AssuranceProfileComponent.Updates]: React.FC<DeleteUpdatePopconfirmProps>;
  [AssuranceProfileComponent.Logo]?: React.FC<DeleteLogoPopconfirmProps>;
}
export interface CreateTileItemModalProps extends ModalProps {
  tileId: TileId;
}

export interface EditTileItemModalProps extends ModalProps {
  tileItem: TileItem;
}

export interface DeleteTileItemPopconfirmProps {
  tileItemId: TileItemId;
}

export interface DeleteTilePopconfirmProps {
  tileId: TileId;
}

export interface EditRequestInfoSettingsModalProps extends ModalProps {
  profileVersionId: ProfileVersionId;
}

export interface EditLogoModalProps extends ModalProps {
  logo: Logo;
}

export interface UpdateCertificationModalProps extends ModalProps {
  certification: Certification;
}

export interface RequestInfoFormHiddenBannerProps {
  profileVersion: ProfileVersion;
}

interface AssuranceProfileEditModals {
  [AssuranceProfileComponent.Overview]?: React.FC<ModalProps>;
  [AssuranceProfileComponent.Certifications]: React.FC<ModalProps>;
  [AssuranceProfileComponent.Updates]?: React.FC<EditUpdateModalProps>;
  [AssuranceProfileComponent.Subprocessors]: React.FC<EditSubprocessorModalProps>;
  [AssuranceProfileComponent.Title]?: React.FC<ModalProps>;
  [AssuranceProfileComponent.Tiles]: React.FC<EditTileModalProps>;
  [AssuranceProfileComponent.TileItems]: React.FC<EditTileItemModalProps>;
  [AssuranceProfileComponent.RequestInfoSettings]?: React.FC<EditRequestInfoSettingsModalProps>;
  [AssuranceProfileComponent.Logo]?: React.FC<ModalProps>;
  [AssuranceProfileComponent.RequestInfoSubtitle]?: React.FC<ModalProps>;
  [AssuranceProfileComponent.RequestInfoTitle]?: React.FC<ModalProps>;
}

interface AssuranceProfileCreateModals {
  [AssuranceProfileComponent.Certifications]?: React.FC<ModalProps>;
  [AssuranceProfileComponent.Updates]?: React.FC<ModalProps>;
  [AssuranceProfileComponent.Subprocessors]: React.FC<ModalProps>;
  [AssuranceProfileComponent.Tiles]: React.FC<ModalProps>;
  [AssuranceProfileComponent.TileItems]: React.FC<CreateTileItemModalProps>;
  [AssuranceProfileComponent.Logo]?: React.FC<ModalProps>;
}

interface AssuranceProfileOtherComponents {
  [AssuranceProfileComponent.RequestInformationFormHiddenBanner]: React.FC<RequestInfoFormHiddenBannerProps>;
}

export interface EditorComponents {
  editModals: AssuranceProfileEditModals;
  createModals: AssuranceProfileCreateModals;
  deletionPopconfirms: AssuranceProfileDeletePopconfirms;
  others: AssuranceProfileOtherComponents;
}

interface AssuranceProfileWrapperProps {
  profileVersion: ProfileVersion;
  editorComponents?: EditorComponents;
  requestInfoProps: {
    disabled?: boolean;
    handleSubmit?: (
      values: RequestInfoFormParams,
      turnstileToken: string,
    ) => Promise<void>;
    turnstileKey?: string;
    hidden?: boolean;
    submitSuccess?: boolean;
    submitError?: boolean;
  };
  tileProps?: {
    onMoveTile: (tileId: TileId, direction: string) => void;
    onMoveTileItem: (tileItemId: TileItemId, direction: string) => void;
  };
  subprocessorProps?: {
    onMoveSubprocessor: (
      subprocessorId: SubprocessorId,
      direction: string,
    ) => void;
  };
  certificationSelectionProps?: {
    onMoveCertificationSelection: (
      certificationSelectionId: CertificationSelectionId,
      direction: string,
    ) => void;
  };
  isDarkModeOn: boolean;
  profileSubscriptionProps?: {
    handleSubscribe: (email: string, turnstileToken: string) => Promise<void>;
    hasSubscribed: boolean;
  };
  isSubscriptionEnabled?: boolean;
  downloadPublicDocumentUrl: string;
  documentSectionEnabled?: boolean;
  showDocumentSectionEditButton?: boolean;
  onBulkDownloadPublicDocuments?: (
    documentIds: CustomerAssuranceDocumentId[],
  ) => Promise<void>;
}

export const ProfileVersionContext = createContext<ProfileVersion | null>(null);
const AssuranceProfileWrapper = ({
  profileVersion,
  editorComponents,
  requestInfoProps,
  tileProps,
  subprocessorProps,
  certificationSelectionProps,
  isDarkModeOn,
  downloadPublicDocumentUrl,
  documentSectionEnabled = false,
  showDocumentSectionEditButton,
  onBulkDownloadPublicDocuments,
  isSubscriptionEnabled,
  profileSubscriptionProps,
}: AssuranceProfileWrapperProps): JSX.Element => {
  const { editModals, createModals, deletionPopconfirms } =
    editorComponents || {};
  const includeOverview =
    editModals?.Overview || profileVersion.attributes.overview;
  const includeCertifications =
    editModals?.Certifications ||
    profileVersion.relationships.certificationSelections.length > 0;
  const profileTiles = profileVersion.relationships.tiles;
  const includeTiles = editModals?.Tiles || profileTiles.length > 0;
  const assuranceProfileUpdatesToShow = editModals?.Updates
    ? profileVersion.relationships.assuranceProfileUpdates
    : profileVersion.relationships.assuranceProfileUpdates.filter(
        (update) => update.attributes.publishedAt,
      );
  const includeUpdates =
    editModals?.Updates || assuranceProfileUpdatesToShow.length > 0;
  const includeSubprocessors =
    editModals?.Subprocessors ||
    profileVersion.relationships.subprocessors.length > 0;

  const includeLogo = createModals?.Logo || !!profileVersion.relationships.logo;

  const includeTitle = editModals?.Title || !!profileVersion.attributes.title;

  const styleSettings = profileVersion.relationships.styleSettings.attributes;

  const showRequestInfoForm =
    profileVersion.relationships.requestInformationSettings.attributes
      .showForm && !requestInfoProps.hidden;
  const customerAssuranceDocuments = profileVersion.relationships.documents;
  const requestInfoFormRef = useRef(null);
  const downloadPublicDocument = async (
    document: CustomerAssuranceDocument,
  ) => {
    // called when viewing the profile in editor/preview mode.
    const blob = await downloadFile(
      `${downloadPublicDocumentUrl}${document.id}`,
    );
    saveAs(blob, document.relationships.file.attributes.fileName);
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.defaultAlgorithm,
        token: {
          colorTextHeading: styleSettings.headerColor,
          colorText: styleSettings.textColor,
          fontFamily: `${styleSettings.font},sans-serif`,
        },
      }}
    >
      <style>{styleSettings.css}</style>
      <ProfileVersionContext.Provider value={profileVersion}>
        <div
          className={styles.Page}
          style={{
            backgroundColor: styleSettings.backgroundColor,
          }}
        >
          <div className={clsx(["AssuranceProfileWrapper", styles.Wrapper])}>
            {profileVersion.attributes.companyName === "SecurityPal" && (
              <AssuranceProfileBanner profileVersion={profileVersion} />
            )}
            <div className={styles.LogoAndTitleAndSubscribeButton}>
              <div className={styles.LogoAndTitle}>
                {includeLogo && (
                  <div className={`${styles.Logo} Logo`}>
                    <AssuranceProfileLogo
                      logo={profileVersion.relationships.logo}
                      companyName={profileVersion.attributes.companyName}
                      CreateModal={createModals?.Logo}
                      EditModal={editModals?.Logo}
                      DeletePopconfirm={deletionPopconfirms?.Logo}
                    />
                  </div>
                )}
                {includeTitle && (
                  <AssuranceProfileTitle
                    title={profileVersion.attributes.title}
                    EditModal={editModals?.Title}
                    color={styleSettings.titleColor}
                  />
                )}
              </div>
              {isSubscriptionEnabled && (
                <AssuranceProfileSubscription
                  handleSubscribe={profileSubscriptionProps?.handleSubscribe}
                  profileVersion={profileVersion}
                  hasSubscribed={profileSubscriptionProps?.hasSubscribed}
                  turnstileKey={requestInfoProps?.turnstileKey}
                />
              )}
            </div>
            <div className={styles.OverviewAndCertifications}>
              <Row gutter={[30, 30]}>
                {includeOverview && (
                  <Col
                    xs={24}
                    lg={includeCertifications && includeOverview ? 12 : 24}
                  >
                    <Overview
                      overview={profileVersion.attributes.overview}
                      EditModal={editModals?.Overview}
                    />
                  </Col>
                )}
                {includeCertifications && (
                  <Col
                    xs={24}
                    lg={includeCertifications && includeOverview ? 12 : 24}
                  >
                    <CertificationSelections
                      selections={
                        profileVersion.relationships.certificationSelections
                      }
                      CreateModal={createModals?.Certifications}
                      EditModal={editModals?.Certifications}
                      onMoveCertificationSelection={
                        certificationSelectionProps?.onMoveCertificationSelection
                      }
                    />
                  </Col>
                )}
              </Row>
            </div>
            {documentSectionEnabled &&
              customerAssuranceDocuments.length > 0 &&
              (showRequestInfoForm ||
                customerAssuranceDocuments.filter((d) => d.attributes.isPublic)
                  .length > 0) &&
              onBulkDownloadPublicDocuments && (
                <DocumentsSection
                  profileVersion={profileVersion}
                  customerAssuranceDocuments={customerAssuranceDocuments}
                  requestInfoFormRef={requestInfoFormRef}
                  onDownloadPublicDocument={downloadPublicDocument}
                  onBulkDownloadPublicDocuments={onBulkDownloadPublicDocuments}
                  showEditButton={showDocumentSectionEditButton}
                />
              )}

            {includeTiles && (
              <AssuranceProfileSearch
                tileItems={profileTiles
                  .map((tile) => tile.relationships.tileItems)
                  .flat()}
                updates={assuranceProfileUpdatesToShow}
                isDarkModeOn={isDarkModeOn}
              />
            )}

            {includeTiles && (
              <div className={styles.Tiles}>
                <Tiles
                  tiles={profileTiles}
                  editorComponents={
                    editorComponents && {
                      CreateTileModal: editorComponents.createModals.Tiles,
                      EditTileModal: editorComponents.editModals.Tiles,
                      CreateTileItemModal:
                        editorComponents.createModals.TileItems,
                      EditTileItemModal: editorComponents.editModals.TileItems,
                      DeleteTileItemPopconfirm:
                        editorComponents.deletionPopconfirms.TileItems,
                      DeleteTilePopconfirm:
                        editorComponents.deletionPopconfirms.Tiles,
                    }
                  }
                  onMoveTile={tileProps?.onMoveTile}
                  onMoveTileItem={tileProps?.onMoveTileItem}
                />
              </div>
            )}
            {includeUpdates && (
              <AssuranceProfileUpdates
                updates={profileVersion.relationships.assuranceProfileUpdates}
                EditModal={editModals?.Updates}
                CreateModal={createModals?.Updates}
                DeletePopconfirm={deletionPopconfirms?.Updates}
              />
            )}
            {includeSubprocessors && (
              <Subprocessors
                profileVersion={profileVersion}
                editorComponents={
                  editorComponents && {
                    EditModal: editorComponents.editModals.Subprocessors,
                    CreateModal: editorComponents.createModals.Subprocessors,
                    DeletePopconfirm:
                      editorComponents.deletionPopconfirms.Subprocessors,
                  }
                }
                onMoveSubprocessor={subprocessorProps?.onMoveSubprocessor}
              />
            )}
            {editorComponents?.others.RequestInformationFormHiddenBanner &&
              !showRequestInfoForm && (
                <editorComponents.others.RequestInformationFormHiddenBanner
                  profileVersion={profileVersion}
                />
              )}
            {showRequestInfoForm && (
              <RequestInfo
                profileVersion={profileVersion}
                disabled={!!requestInfoProps?.disabled}
                handleSubmit={requestInfoProps?.handleSubmit}
                turnstileKey={requestInfoProps?.turnstileKey}
                EditModal={editorComponents?.editModals.RequestInfoSettings}
                EditSubtitleModal={
                  editorComponents?.editModals.RequestInfoSubtitle
                }
                EditInfoTitleModal={
                  editorComponents?.editModals.RequestInfoTitle
                }
                requestInfoFormRef={requestInfoFormRef}
                submitSuccess={requestInfoProps?.submitSuccess}
                submitError={requestInfoProps?.submitError}
              />
            )}
          </div>

          <ProfileFooter />
        </div>
      </ProfileVersionContext.Provider>
    </ConfigProvider>
  );
};

export default AssuranceProfileWrapper;
